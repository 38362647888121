import React from 'react';
import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import SolutionsV2Card from './SolutionsV2Card';

export default function SolutionsV2CardDeck({ component }) {
  const { cards, heading, headingKicker } = component;
  return (
    <StyledContainer>
      <div className="header-container">
        <h2>{heading}</h2>
        <h4>{headingKicker}</h4>
      </div>
      <StyledCardContainer>
        {cards.map(({ id, image, ...rest }) => (
          <SolutionsV2Card
            key={`sv2c_${id}`}
            {...rest}
            background={image.fluid.src}
          />
        ))}
      </StyledCardContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 24px;
  padding-right: 24px;
  gap: 64px;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    width: 80%;
    flex: 1;

    h2 {
      color: var(--Gray-900, #1d2030);
      text-align: center;
      font-family: CircularXX;
      font-size: 28.13px;

      ${atMinWidth.md`
        font-size: 35.16px;
      `}
      ${atMinWidth.lg`
        font-size: 43.95px;
      `}
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 48.345px */
      letter-spacing: -0.3px;
      z-index: 9;
    }
    h4 {
      color: var(--Gray-600, #6f7283);
      text-align: center;
      font-family: CircularXX;
      font-size: 16px;
      ${atMinWidth.md`
        font-size: 18px;
      `}
      ${atMinWidth.lg`
         font-size: 22.5px;
      `}
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 33.75px */
      letter-spacing: -0.27px;
      z-index: 9;
    }
  }
`;

const StyledCardContainer = styled.div`
  display: grid;
  gap: 15px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  justify-content: center;
  align-items: center;

  ${atMinWidth.md`
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  `}

  ${atMinWidth.lg`
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  `}

  ${atMinWidth.xl`
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  `}
  
  a {
    text-decoration: none;
  }
  a::after {
    text-decoration: none;
  }
`;
